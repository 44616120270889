import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un mamelló al capell pla i ample, molt viscós de color marró que destaca del marge del capell color crema groguenc. El capell primer és convex i passa a aplanat de fins a 10 cm de diàmetre. Les làmines estan separades, de color crema rosat d’aspecte cerós i el peu és cilíndric amb la base en forma de punta, llarg, viscós i de color blanquinós amb tons crema rosat. Les espores són blanques en massa, el·líptiques, de 9-11 x 5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      